import 'react-app-polyfill/ie11'
import {Config} from './config/config'
import 'index.css'

/*
 * Инициализируем настройки и запускаем приложение
 * */

Config.init(
  process.env.REACT_APP_TYPE
    ? {
        isAdmin: process.env.REACT_APP_TYPE === 'admin',
      }
    : undefined
).then(() => {
  import('./App')
})
